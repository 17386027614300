// import { useDemoData } from '@mui/x-data-grid-generator';
import {
  DataGrid,
  GridToolbar,
  GridSortModel,
  GridFilterModel,
  GridFilterItem,
} from "@mui/x-data-grid";
import * as React from "react";
// import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Popover,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCustomerData,
  updateSingleUser,
  sortedCustomerData,
  manualConnection,
  fetchAgentsData,
  updateAgentStatus,
  resetWebhook,
  updateWalletBalance,
  updateWalletCurrency,
} from "../Services/index";
import { FormEvent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import CustomHomeGridFilter from "./CustomHomeGridFilter";
import CampaignStatusDashboard from "../Components/campaignStatusDashboard"
import ShopifyMessageDashboard from "../Components/ShopifyMessageDashboard"
import WoocommerceDashboard from "../Components/WoocommerceDashboard"
import { WorkFlowIniatedTimeStampDashboard } from "../Components/WorkFlowIniatedTimeStampDashboard.jsx"
import NewAccountCreatedDashboard from "../Components/NewAccountCreatedDashboard"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #ccc",
  maxWidth: "400px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const defaultSortModel: GridSortModel = [{ field: "created_at", sort: "desc" }];

export default function DataGridHome() {
  // const { data, loading } = useDemoData({
  //   dataSet: 'Commodity',
  //   rowLength: 4,
  //   maxColumns: 8,
  // });

  //Edit Model starts
  // const apiRef = useGridApiRef();
  const authtoken = localStorage.getItem("token");
  const [customerData, setCustomerData] = useState([]);
  const [agentCustomerData, setAgentCustomerData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalRows: 1,
  });
  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] =
    React.useState<GridSortModel>(defaultSortModel);
  const [filters, setFilters] = useState<GridFilterItem[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [isEditAgents, setIsEditAgents] = useState(false);
  const [isCDFiltered, setIsCDFiltered] = useState(false);
  const [WALogData, setWALogData] = useState([]);

  const [appsumoUsers, setAppsumoUsers] = React.useState(false);
  const [subscribedUsers, setSubscribedUsers] = React.useState(false);
  const [freeTrialUsers, setFreeTrialUsers] = React.useState(false);
  const updateSorted = (vals: GridSortModel) => {
    if (vals.length === 0) {
      vals = defaultSortModel;
      setSortModel(defaultSortModel);
    } else {
      setSortModel(vals);
    }

    const sortArray = [
      "balance.sms",
      "balance.email",
      "balance.whatsapp",
      "plan_end",
      "webhook_status",
      "email",
      "name",
      "mobile",
      "paid_amount",
      "created_at",
    ];

    // console.log("vals", vals[0]);
    // console.log("sortModel", sortModel[0]);

    if (sortArray.includes(vals[0].field)) {
      setLoading(true);

      console.log("Filters", filters);
      // return;

      sortedCustomerData(
        authtoken ?? "",
        pagination.page,
        pagination.limit,
        search,
        vals[0].sort,
        vals[0].field,
        appsumoUsers,
        subscribedUsers,
        freeTrialUsers,
        filters
      )
        .then((res) => {
          setCustomerData(res?.data.data.docs);
          setPagination({
            ...pagination,
            totalRows: res?.data?.data?.total ?? 0,
          });
          if (filters.length && filters[0].value) {
            setIsCDFiltered(true);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getData = () => {
    setLoading(true);
    getCustomerData(authtoken ?? "", pagination.page, pagination.limit, search)
      .then((res) => {
        setCustomerData(res?.data.data.docs);
        setPagination({
          ...pagination,
          totalRows: res?.data?.data?.total ?? 0,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    updateSorted(sortModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authtoken,
    pagination.page,
    pagination.limit,
    appsumoUsers,
    subscribedUsers,
    freeTrialUsers,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateSorted(sortModel);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (filters.length) {
      console.log("Triggered filter updating...");
      updateSorted(sortModel);
    } else if (isCDFiltered) {
      setIsCDFiltered(false);
      updateSorted(sortModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setUpdateConfirm(false);
    setOpen(true);
    console.log("values:", valuesSelect);
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
    console.log("values:", valuesSelect);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //Edit Model ends

  // console.log("props.data ---> ", props.data)
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [agentsLoading, setAgentsLoading] = useState(true);
  const [valuesSelect, setValuesSelect] = useState<any>({});
  const [actionSelect, setActionSelect] = useState("");
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [international, setInternational] = useState(false);
  const [confirm, setConfirm] = useState(true);
  const [manualConnectionData, setManualConnectionData] = useState<any>({
    user_id: "",
    business_id: "",
    display_name: "",
    active: true,
    project_id: "",
    user_name: "",
    email: "",
    company: "",
    contact: "",
    currency: "INR",
    timezone: "Asia/Calcutta GMT+05:30",
    type: "owner",
    companySize: "1",
  });

  useEffect(() => {
    setManualConnectionData({
      ...manualConnectionData,
      user_id: valuesSelect?._id,
      user_name: valuesSelect?.email,
      email: valuesSelect?.email,
      company: valuesSelect?.name,
      display_name: valuesSelect?.name,
      contact: formatPhoneNumber(valuesSelect?.mobile),
    });
    setInternational(valuesSelect?.isInternational);
    console.log(manualConnectionData);
  }, [valuesSelect]);

  const [formdata, setFormdata] = useState({
    currentWaBalance: 0,
    currentEmailBalance: 0,
    currentSMSBalance: 0,
    previousEndDate: "",
    newEndDate: new Date(""),
    monthsToBeAdded: 0,
    premiumPlanName:"",
  });

  const handleValue = (event: any) => {
    const value = event.target.value;
    const max = valuesSelect?.walletBalance;

    if (value < 0) {
      setError("Value cannot be negative");
      setConfirm(false);
    } else if (value > max) {
      setError(`Value cannot be greater than ${max}`);
      setConfirm(false);
    } else {
      setError("");
      setConfirm(true);
    }

    updateData(event);
  };

  const handleSwitch = (event: any) => {
    setInternational(!international);
    console.log("Event : ",event.target.checked);
    updateData(event);
  };

  const updateData = (e: any) => {
    if (e.target.name === "newEndDate") {
      setFormdata({
        ...formdata,
        newEndDate: new Date(e.target.value),
      });
    } else if (
      e.target.name === "smsApiKey" ||
      "smsClientId" || "smsHeader" ||
      "conversationBasedBilling" || "countryBasedBilling" ||
      "phone_number" || "appsumoTier" || "premiumPlanName"
    ) {
      setFormdata({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "isInternational") {
      setFormdata({
        ...formdata,
        [e.target.name]: international,
      });
    } else if (e.target.name === "currentWalletBalance") {
      setFormdata({
        ...formdata,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setFormdata({
        ...formdata,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const formDataValue = new FormData(event.currentTarget);
    // console.log("formDataValue: ", formDataValue)
    // console.log("data.get('currentEmailBalance'): ", formDataValue.get('currentEmailBalance'))
    if (actionSelect === "reset_webhook") {
      resetWebhook(authtoken,valuesSelect?._id)
        .then((response) => {
          toast.success("Webhook reset successfully", { autoClose: 3000 });
          handleClose();
        })
        .catch((error) => {
          console.error("Failed to reset webhook:", error);
          toast.error("Failed to reset webhook", { autoClose: 3000 });
        });
    } 
    else if(actionSelect==='update_wallet_balance'){
      const form = event.currentTarget;
    const newformData = new FormData(form);
    const newBalance = parseInt(newformData.get('currentWalletBalance') as string);

      updateWalletBalance(authtoken,valuesSelect?._id, newBalance)
        .then((response) => {
           toast.success("Wallet balance updated successfully", { autoClose: 3000 });
           updateSorted(sortModel);
          handleClose();
        })
        .catch((error) => {
          console.error("Failed to update wallet balance:", error);
          toast.error("Failed to update wallet balance", { autoClose: 3000 });
        });
    }
    else if(actionSelect==='update_wallet_currency'){
      const form = event.currentTarget;
    const newformData = new FormData(form);
    const newBalance = (newformData.get('currency') as string);

      updateWalletCurrency(authtoken,valuesSelect?._id, newBalance)
        .then((response) => {
           toast.success("Wallet currecny updated successfully", { autoClose: 3000 });
           updateSorted(sortModel);
          handleClose();
        })
        .catch((error) => {
          console.error("Failed to update wallet currency:", error);
          toast.error("Failed to update wallet currency", { autoClose: 3000 });
        });
    }
    else{
    updateSingleUser(
      {
        ...formdata,
        actionSelect: actionSelect,
        clientId: valuesSelect?._id,
        premiumPlanName: formdata?.premiumPlanName,
      },
      authtoken ?? ""
    )
      .then((res) => {
        if (res?.flag) {
          setActionSelect("");
          setFormdata({
            currentWaBalance: 0,
            currentEmailBalance: 0,
            currentSMSBalance: 0,
            previousEndDate: "",
            newEndDate: new Date(),
            monthsToBeAdded: 0,
            premiumPlanName:""
          });
          toast.success(res?.data?.message, { autoClose: 3000 });
          handleClose();
          // getData();
          updateSorted(defaultSortModel);
          // localStorage.setItem('token', res.data.token);
          // setTimeout(() => {
          //    navigate('/app');
          // }, 1000);
        } else {
          toast.error(res?.message, { autoClose: 3000 });
          console.log(res);
        }
      })
      .catch((e) => {
        toast.error("Update Failed", { autoClose: 3000 });
      });
    }
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber?.length === 10) {
      return "91" + phoneNumber;
    } else if (phoneNumber?.length === 12) {
      return phoneNumber;
    } else {
      return "Invalid phone number format";
    }
  };

  const setFormDataDefaults = (zData: any) => {
    setFormdata({
      currentWaBalance: zData?.balance?.whatsapp ?? 0,
      currentEmailBalance: zData?.balance?.email ?? 0,
      currentSMSBalance: zData?.balance?.sms ?? 0,
      previousEndDate: zData?.plan_end ?? "",
      newEndDate: zData?.plan_end ?? "",
      monthsToBeAdded: 0,
      premiumPlanName:"",
    });
  };

  const handleConnect = () => {
    manualConnection(manualConnectionData, authtoken ?? "")
      .then((res) => {
        if (res?.flag) {
          toast.success("Manual Connection Successful", { autoClose: 3000 });
          setOpen(false);
          setIsConnect(false);
          setManualConnectionData({
            ...manualConnectionData,
            project_id: "",
            business_id: "",
          });
        } else {
          toast.error(res?.message, { autoClose: 3000 });
        }
      })
      .catch((e) => {
        toast(e, { autoClose: 3000 });
      });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      hide: false,
      width: 140,
      renderCell: (params: any) => {
        return params?.row?.name ?? "NA";
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
      filterable: false,
      renderCell: (params: any) => {
        return params?.row?.email ?? "NA";
      },
    },
    {
      field: "user_id",
      headerName: "User Id",
      width: 200,
      editable: false,
      filterable: false,
      renderCell: (params: any) => {
        return params?.row?._id ?? "NA";
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      valueFormatter: (params: any) => {
        return params?.value === "" ? "NA" : params?.value;
      },
      renderCell: (params: any) => {
        return params?.value === "" ? "NA" : params?.value;
      },
      editable: false,
      filterable: false,
    },
    {
      field: "plan_id",
      headerName: "Plan Id",
      width: 80,
      editable: false,
      valueFormatter: (params: any) => `${params?.value ?? "NA"}`,
      renderCell: (params: any) => {
        return params?.row?.plan_id ?? "NA";
      },
      filterable: false,
    },
    {
      field: "paid_amount",
      headerName: "Amount Paid",
      width: 80,
      editable: false,
      valueFormatter: (params: any) => `${params?.value ?? "NA"}`,
      renderCell: (params: any) => {
        return params?.row?.paid_amount ?? "NA";
      },
      filterable: false,
    },
    {
      field: "balance.sms",
      headerName: "SMS Balance",
      type: "number",
      width: 80,
      editable: false,
      valueGetter: (params: any) => {
        return params?.row?.balance?.sms;
      },
      valueFormatter: (params: any) => params?.value ?? "NA",
      renderCell: (params: any) => {
        return params?.row?.balance?.sms ?? "NA";
      },
    },
    {
      field: "balance.email",
      headerName: "Email Balance",
      type: "number",
      width: 80,
      editable: false,
      valueGetter: (params: any) => {
        return params?.row?.balance?.email;
      },
      valueFormatter: (params: any) => params?.value ?? "NA",

      renderCell: (params: any) => {
        return params?.value ?? "NA";
      },
    },
    {
      field: "balance.whatsapp",
      headerName: "Whatsapp Balance",
      type: "number",
      width: 80,
      editable: false,
      valueGetter: (params: any) => {
        return params?.row?.balance?.whatsapp;
      },
      valueFormatter: (params: any) => params?.value ?? "NA",

      renderCell: (params: any) => {
        return params?.value ?? "NA";
      },
    },
    {
      field: "plan_end",
      headerName: "End Date",
      type: "dateTime",
      width: 200,
      editable: false,
      valueFormatter: (params: any) => {
        return params?.value ? new Date(params.value) : "NA";
      },
      renderCell: (params: any) => {
        function beautifyDate(dateObj: Date) {
          const dateTimeStr = dateObj.toLocaleString("en-US", {
            dateStyle: "medium",
            timeStyle: "medium",
            hourCycle: "h12",
          });

          return dateTimeStr;
        }

        return params?.row?.plan_end
          ? beautifyDate(new Date(params.row.plan_end))
          : "NA";
      },
    },
    {
      field: "webhook_status",
      headerName: "Webhook Status",
      width: 120,
      editable: false,
      renderCell: (params: any) => {
        return params?.row?.webhookStatus ? "Subscribed" : "Not Subscribed";
      },
      filterable: false,
    },
    {
      field: "conversationBasedBilling",
      headerName: "Conversation Based Billing",
      width: 120,
      editable: false,
      renderCell: (params: any) => {
        return params?.row?.conversationBasedBilling
          ? "Subscribed"
          : "Not Subscribed";
      },
      filterable: false,
    },
    {
      field: "Agents",
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsEditAgents(true);
                getAgentsData(cellValues.row._id);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
      filterable: false,
    },
    {
      field: "Edit",
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsEdit(true);
                handleOpen();
                console.log("Cell values: ", cellValues.row);
                setValuesSelect(cellValues.row);
                setFormDataDefaults(cellValues.row);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
      filterable: false,
    },
    {
      field: "Manual Connection",
      width: 130,
      renderCell: (cellValues: any) => {
        return (
          <>
            {!cellValues?.row?.webhookStatus && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsConnect(true);
                  setOpen(true);
                  setValuesSelect(cellValues.row);
                  setFormDataDefaults(cellValues.row);
                }}
              >
                Connect
              </Button>
            )}
          </>
        );
      },
      filterable: false,
    },
  ];

  const agentColumns = [
    {
      field: "name",
      headerName: "Name",
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.name ?? "NA";
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.email ?? "NA";
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      hide: false,
      renderCell: (params: any) => {
        return params?.row?.phone ?? "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      hide: false,
      width: 180,
      renderCell: (params: any) => {
        return (
          <FormControl fullWidth>
            <Select
              label="status"
              id="statuse-select"
              labelId="status-select-label"
              value={params?.row?.status === 1 ? "Pending" : "Active"}
              onChange={(ev) => handleAgentStatusChange(ev, params.row._id)}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
  ];

  const handleAgentStatusChange = (event: any, agentId: string) => {
    const status = event.target.value === "Pending" ? 1 : 2;
    updateAgentStatus(authtoken ?? "", agentId, status)
      .then((updatedSubUser) => {
        setAgentCustomerData((prev) =>
          prev.map((agentData) => {
            if (agentData._id === agentId) {
              return updatedSubUser.data;
            }
            return agentData;
          })
        );
      })
      .catch((error) => {
        console.error("Failed to update agent status:", error);
        toast.error("Failed to update agent status");
      });
  };

  const getAgentsData = async (userSpecificId: string) => {
    setAgentsLoading(true);
    fetchAgentsData(authtoken ?? "", userSpecificId)
      .then((response) => {
        setAgentCustomerData(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch agents data:", error);
        toast.error("Failed to fetch agents data");
      })
      .finally(() => {
        setAgentsLoading(false);
      });
  };


  const handleFilterModeChange = (model: GridFilterModel) => {
    console.log("handleFilterModeChange");
    if (
      model.items.length &&
      (model.items[0].operator === "isEmpty" ||
        model.items[0].operator === "isNotEmpty")
    ) {
      setFilters([{ ...model.items[0], value: "." }]);
    } else {
      setFilters(model.items);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);

  return (
    <div style={{ height: 550, width: "100%" }}>
      <div
        style={{
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <CampaignStatusDashboard />
        <ShopifyMessageDashboard />
        <WoocommerceDashboard />
        <WorkFlowIniatedTimeStampDashboard />
        <NewAccountCreatedDashboard />
      </div>
      <Box display="flex" alignItems="center">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Search>

        <Box
          display="flex"
          alignItems="center"
          borderRadius={8}
          padding="0px 6px"
        >
          <Button variant="text" onClick={handleClickFilter}>
            <FilterListIcon /> Filter
          </Button>
          <Popover
            open={openFilter}
            anchorEl={anchorEl}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <CustomHomeGridFilter filters={filters} setFilters={setFilters} />
          </Popover>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          borderRadius={8}
          padding="0px 6px"
        >
          <Typography color="gray">Appsumo Users</Typography>
          <Switch
            checked={appsumoUsers}
            onChange={() => setAppsumoUsers((p) => !p)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          borderRadius={8}
          padding="0px 6px"
        >
          <Typography color="gray">Subscribed Users</Typography>
          <Switch
            checked={subscribedUsers}
            onChange={() => setSubscribedUsers((p) => !p)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          borderRadius={8}
          padding="0px 6px"
        >
          <Typography color="gray">FreeTrial Users</Typography>
          <Switch
            checked={freeTrialUsers}
            onChange={() => setFreeTrialUsers((p) => !p)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      </Box>
      {/* // Filter is not working [Remove Filter Button]
      // Add disableColumnFilter Date: 03/11 */}
      <DataGrid
        rows={customerData}
        columns={columns}
        getRowId={(row: any) => row._id}
        loading={loading}
        disableColumnFilter
        slots={{ toolbar: GridToolbar }}
        rowCount={pagination.totalRows}
        pageSizeOptions={[10, 20, 25, 50, 75, 100]}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.limit,
        }}
        filterMode="server"
        onFilterModelChange={handleFilterModeChange}
        onSortModelChange={(newSortModel) => updateSorted(newSortModel)}
        slotProps={{
          toolbar: {
            csvOptions: {
              allColumns: true,
              fields: [
                "name",
                "email",
                "user_id",
                "mobile",
                "balance.sms",
                "balance.email",
                "balance.whatsapp",
                "plan_end",
                "paid_amount",
                "webhook_status",
              ],
            },
          },
          filterPanel: {
            filterFormProps: {
              filterColumns: () => [
                "name",
                "balance.sms",
                "balance.email",
                "balance.whatsapp",
                "plan_end",
              ],
              logicOperatorInputProps: {
                variant: "outlined",
                size: "small",
              },
              columnInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              operatorInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              deleteIconProps: {
                sx: {
                  display: "none",
                  margin: "auto",
                  "& .MuiSvgIcon-root": { color: "#d32f2f" },
                },
              },
            },
            sx: {
              border: "px solid red",
              "& .MuiDataGrid-filterForm": {
                p: 2,
                flexDirection: "column",
                gap: "16px",
              },
              "& .MuiDataGrid-filterForm:nth-child(even)": {
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
              },
              "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
              "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: "100%" },
              "& .MuiDataGrid-filterFormOperatorInput": {
                mr: 2,
                width: "100%",
              },
              "& .MuiDataGrid-filterFormValueInput": { width: "100%" },
            },
          },
        }}
        onPaginationModelChange={(params) => {
          setPagination({
            ...pagination,
            page: params.page,
            limit: params.pageSize,
          });
        }}
      />

      <Modal
        open={isEditAgents}
        onClose={() => {
          setIsEditAgents(false);
          setAgentCustomerData([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DataGrid
            style={{ minHeight: "320px", maxHeight: "720px" }}
            rows={agentCustomerData}
            columns={agentColumns}
            getRowId={(row: any) => row._id}
            loading={agentsLoading}
            // slots={{ toolbar: GridToolbar }}
            disableColumnFilter
            // checkboxSelection
            rowCount={pagination.totalRows}
            pageSizeOptions={[10, 20, 25, 50, 75, 100]}
            paginationMode="server"
            paginationModel={{
              page: pagination.page,
              pageSize: pagination.limit,
            }}
            // initialState={{
            //   sorting: {
            //     sortModel: [{ field: "name", sort: "desc" }],
            //   },
            // }}
            // sortModel={sortModel}
            // onSortModelChange={(newSortModel) => updateSorted(newSortModel)}
            slotProps={{
              toolbar: {
                csvOptions: {
                  allColumns: true,
                  fields: ["name", "email", "mobile", "status"],
                },
              },
            }}
            onPaginationModelChange={(params) => {
              setPagination({
                ...pagination,
                page: params.page,
                limit: params.pageSize,
              });
            }}
          />
        </Box>
      </Modal>

      {isEdit && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    onChange={updateData}
                    margin="normal"
                    required
                    fullWidth
                    id="Name"
                    label="Name"
                    name="Name"
                    autoComplete="Name"
                    defaultValue={valuesSelect?.name}
                    autoFocus
                    disabled
                  />

                  {/* <TextField
                margin="normal"
                required
                fullWidth
                name="planid"
                label="planid"
                type="planid"
                id="planid"
                defaultValue={valuesSelect?.plan_id}
              />
                <TextField
                 onChange={updateData}
                margin="normal"
                required
                fullWidth
                 name="endDate"
                label="endDate"
                type="endDate"
                id="endDate"
                defaultValue={valuesSelect?.plan_end}
              />*/}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={updateData}
                    margin="normal"
                    required
                    fullWidth
                    name="mobile"
                    label="mobile"
                    type="mobile"
                    defaultValue={valuesSelect?.mobile}
                    id="mobile"
                    disabled
                  />
                  {/* <TextField
                margin="normal"
                required
                fullWidth
                name="amount"
                label="amount"
                type="amount"
                id="amount"
                defaultValue={valuesSelect?.paid_amount}
              />
              <TextField
               onChange={updateData}
                margin="normal"
                required
                fullWidth
                name="waBalance"
                label="waBalance"
                type="waBalance"
                id="waBalance"
                defaultValue={valuesSelect?.balance?.whatsapp}
              />
              <TextField
               onChange={updateData}
                margin="normal"
                required
                fullWidth
                name="emailBalance"
                label="emailBalance"
                type="emailBalance"
                id="emailBalance"
                defaultValue={valuesSelect?.balance?.email}
              />
              <TextField
               onChange={updateData}
                margin="normal"
                required
                fullWidth
                name="smsBalance"
                label="smsBalance"
                type="smsBalance"
                id="smsBalance"
                defaultValue={valuesSelect?.balance?.sms}
              /> */}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="demo-simple-select-label">
                    Select Action
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={actionSelect}
                    label="Select Action"
                    onChange={(e) => setActionSelect(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={"balance_updated"}>
                      Update Balance
                    </MenuItem>
                    <MenuItem value={"license_updated"}>
                      Update License
                    </MenuItem>
                    <MenuItem value={"end_premium"}>
                      End Premium
                    </MenuItem>
                    <MenuItem value={"reset_webhook"}>
                      Reset Webhook
                    </MenuItem>
                    <MenuItem value={"update_wallet_balance"}>
                      Update Wallet Balance
                    </MenuItem>
                    <MenuItem value={"update_wallet_currency"}>
                      Update Wallet Currency
                    </MenuItem>
                    <MenuItem value={"plan_end_date_update"}>
                      Add Plan End Date
                    </MenuItem>
                    <MenuItem value={"webhook_subscribe"}>
                      Subscribe Webhook
                    </MenuItem>
                    <MenuItem value={"regenerateToken"}>
                      Regenerate Token
                    </MenuItem>
                    <MenuItem value={"setup_sms"}>Setup Sms</MenuItem>
                    <MenuItem value={"conversationBasedBilling"}>
                      Conversation Based Billing
                    </MenuItem>
                    <MenuItem value={"waConfigNumber"}>WABA Number</MenuItem>
                    <MenuItem value={"moveBalanceToPartner"}>
                      Move balance to partner
                    </MenuItem>
                    <MenuItem value={"addBalanceToPartner"}>
                      Add wallet balance to partner
                    </MenuItem>
                    <MenuItem value={"toggleInternational"}>
                      Toggle International
                    </MenuItem>
                    <MenuItem value={"countryBasedBilling"}>
                      Country Based Billing
                    </MenuItem>
                    {valuesSelect?.isAppsumoUser && <MenuItem value={"appsumo_tier"}>
                      Update Appsumo Tier
                    </MenuItem>}
                  </Select>
                </Grid>

                {actionSelect === "license_updated" ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousEndDate"
                        label="previousEndDate"
                        type="previousEndDate"
                        id="previousEndDate"
                        defaultValue={valuesSelect?.plan_end}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="premium-simple-select-label">
                        Select Premium
                      </InputLabel>
                      <Select
                        labelId="premium-simple-select-label"
                        label="Select Premium"
                        name="monthsToBeAdded"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={1}>1 Month</MenuItem>
                        <MenuItem value={3}>3 Months</MenuItem>
                        <MenuItem value={6}>6 Months</MenuItem>
                        <MenuItem value={12}>12 Months</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="premium-simple-select-label">
                        Select Premium Plan
                      </InputLabel>
                      <Select
                        labelId="premium-simple-select-label"
                        label="Select Premium Plan"
                        name="premiumPlanName"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={'Basic'}>Basic</MenuItem>
                        <MenuItem value={'Startup'}>Startup</MenuItem>
                        <MenuItem value={'Growth'}>Growth</MenuItem>
                        <MenuItem value={'Pro'}>Pro</MenuItem>
                        <MenuItem value={'Enterprise'}>Enterprise</MenuItem>
                      </Select>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "plan_end_date_update" ? (
                  <>
                    {console.log(valuesSelect?.plan_end)}
                    {console.log(new Date(valuesSelect?.plan_end))}

                    <Grid item xs={12}>
                      <input
                        type="date"
                        onChange={updateData}
                        required
                        name="newEndDate"
                        id="newEndDate"
                        defaultValue={valuesSelect?.plan_end ?? ""}
                        style={{ width: "100%", padding: "15px 10px" }}
                      />
                      <p>
                        Current Plan End Date is{" "}
                        {valuesSelect?.plan_end
                          ? new Date(valuesSelect?.plan_end).toLocaleString(
                              "en-IN",
                              { timeZone: "Asia/Kolkata" }
                            )
                          : "Expired"}
                      </p>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "waConfigNumber" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="phone_number"
                        label="WABA Number"
                        type="phone_number"
                        id="phone_number"
                        defaultValue={
                          valuesSelect?.phone_number &&
                          valuesSelect?.phone_number.length === 10
                            ? `91${valuesSelect?.phone_number}`
                            : valuesSelect?.phone_number
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {actionSelect === "webhook_subscribe" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="WebhookStatus"
                        label="Webhook Status"
                        type="webhookStatus"
                        id="webhookStatus"
                        defaultValue={
                          valuesSelect?.webhookStatus
                            ? "Subscribed"
                            : "Not Subscribed"
                        }
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "conversationBasedBilling" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        // name="conversationBasedBilling"
                        label="Conversation Based Billing"
                        type="conversationBasedBilling"
                        id="conversationBasedBilling"
                        defaultValue={
                          valuesSelect?.conversationBasedBilling
                            ? "Subscribed"
                            : "Not Subscribed"
                        }
                        disabled
                      />
                      <InputLabel id="conversationBasedBilling">
                        Select Option
                      </InputLabel>
                      <Select
                        labelId="conversationBasedBilling"
                        label="Select Option"
                        name="conversationBasedBilling"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={"subscribe"}>Subscribe</MenuItem>
                        <MenuItem value={"unsubscribe"}>Unsubscribe</MenuItem>
                      </Select>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "setup_sms" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="smsApiKey"
                        label="Sms ApiKey"
                        type="apikey"
                        id="apikey"
                      />

                      <TextField
                        onChange={updateData}
                        margin="normal"
                        fullWidth
                        name="smsClientId"
                        label="Sms ClientId"
                        type="clientid"
                        id="clientid"
                      />
                    
                    <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="smsHeader"
                        label="Sms Header"
                        type="clientid"
                        id="clientid"
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {actionSelect === "balance_updated" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousWaBalance"
                        label="Previous WA Balance"
                        type="previousWaBalance"
                        id="previousWaBalance"
                        defaultValue={valuesSelect?.balance?.whatsapp}
                        disabled
                      />
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousEmailBalance"
                        label="Previous Email Balance"
                        type="previousEmailBalance"
                        id="previousEmailBalance"
                        defaultValue={valuesSelect?.balance?.email}
                        disabled
                      />
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousSMSBalance"
                        label="Previous SMS Balance"
                        type="previousSMSBalance"
                        id="previousSMSBalance"
                        disabled
                        defaultValue={valuesSelect?.balance?.sms}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="currentWaBalance"
                        label="Current WA Balance"
                        type="number"
                        id="currentWaBalance"
                        defaultValue={valuesSelect?.balance?.whatsapp}
                      />
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="currentEmailBalance"
                        label="Current Email Balance"
                        type="number"
                        id="currentEmailBalance"
                        defaultValue={valuesSelect?.balance?.email}
                      />
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="currentSMSBalance"
                        label="Current SMS Balance"
                        type="number"
                        id="currentSMSBalance"
                        defaultValue={valuesSelect?.balance?.sms}
                      />
                      <span>
                        <b>Note:</b> Same Balance will be shown to user
                      </span>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                  {actionSelect === "update_wallet_balance" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousWalletBalance"
                        label="Previous Wallet Balance"
                        type="previousWalletBalance"
                        id="previousWalletBalance"
                        defaultValue={valuesSelect?.walletBalance}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="currentWalletBalance"
                        label="Current Wallet Balance"
                        type="number"
                        id="currentWalletBalance"
                        defaultValue={valuesSelect?.walletBalance}
                      />
                      <span>
                        <b>Note:</b> Same Balance will be shown to user
                      </span>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

{actionSelect === "update_wallet_currency" ? (
                  <>
                    <Grid item xs={12}>
                    <InputLabel id="premium-simple-select-label">
                        Select Default Wallet Currency
                      </InputLabel>
                      <Select
                        label="Select Wallet Currency"
                        name="currency"
                        type='string'
                        id="currency"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={'USD'}>USD</MenuItem>
                        <MenuItem value={'INR'}>INR</MenuItem>
                      </Select>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "moveBalanceToPartner" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="currentWalletBalance"
                        label="Current wallet Balance"
                        type="number"
                        id="currentWalletBalance"
                        inputProps={{
                          readOnly: true,
                        }}
                        defaultValue={valuesSelect?.walletBalance}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="currentPartnerBalance"
                        label="Current partner Balance"
                        type="number"
                        id="currentPartnerBalance"
                        inputProps={{
                          readOnly: true,
                        }}
                        defaultValue={valuesSelect?.partnerBalance}
                      />
                      <span>
                        <b>Note:</b> Same Balance will be shown to user
                      </span>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "addBalanceToPartner" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={handleValue}
                        margin="normal"
                        required
                        fullWidth
                        name="currentWalletBalance"
                        label="Current wallet Balance"
                        type="number"
                        id="currentWalletBalance"
                        inputProps={{
                          min: 0,
                          max: valuesSelect?.walletBalance,
                        }}
                        error={!!error}
                        helperText={error}
                        defaultValue={valuesSelect?.walletBalance}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="currentPartnerBalance"
                        label="Current partner Balance"
                        type="number"
                        id="currentPartnerBalance"
                        inputProps={{
                          readOnly: true,
                        }}
                        defaultValue={valuesSelect?.partnerBalance}
                      />
                      <span>
                        <b>Note:</b> Same Balance will be shown to user
                      </span>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {actionSelect === "toggleInternational" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="isInternational"
                        type="Boolean"
                        id="isInternational"
                        defaultValue={
                          valuesSelect?.isInternational ? true : false
                        }
                        disabled
                      />
                      <Switch checked={international} name={"isInternational"} onChange={handleSwitch} />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {actionSelect === "countryBasedBilling" ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        label="Country Based Billing"
                        type="countryBasedBilling"
                        id="countryBasedBilling"
                        defaultValue={
                          valuesSelect?.countryBasedBilling
                            ? "Subscribed"
                            : "Not Subscribed"
                        }
                        disabled
                      />
                      <InputLabel id="countryBasedBilling">
                        Select Option
                      </InputLabel>
                      <Select
                        labelId="countryBasedBilling"
                        label="Select Option"
                        name="countryBasedBilling"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={"subscribe"}>Subscribe</MenuItem>
                        <MenuItem value={"unsubscribe"}>Unsubscribe</MenuItem>
                      </Select>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {actionSelect === "appsumo_tier" ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        onChange={updateData}
                        margin="normal"
                        required
                        fullWidth
                        name="previousTier"
                        label="previousTier"
                        type="String"
                        id="previousTier"
                        defaultValue={valuesSelect?.appsumo_tier}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="premium-simple-select-label">
                        Select Appsumo Tier
                      </InputLabel>
                      <Select
                        labelId="premium-simple-select-label"
                        label="Select Appsumo Tier"
                        name="appsumoTier"
                        onChange={updateData}
                        fullWidth
                      >
                        <MenuItem value={"App Sumo cheerio_tier1"}>Tier 1</MenuItem>
                        <MenuItem value={"App Sumo cheerio_tier2"}>Tier 2</MenuItem>
                        <MenuItem value={"App Sumo cheerio_tier3"}>Tier 3</MenuItem>
                        <MenuItem value={"App Sumo cheerio_tier4"}>Tier 4</MenuItem>
                      </Select>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={updateConfirm}
                    onChange={() => {
                      setUpdateConfirm(!updateConfirm);
                    }}
                  />
                }
                label="Confirm Update"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!updateConfirm || !confirm}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {isConnect && (
        <Modal
          open={open}
          onClose={() => {
            setIsConnect(false);
            setOpen(false);
            setManualConnectionData({
              ...manualConnectionData,
              project_id: "",
              business_id: "",
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1 }}>
              {/* <form onSubmit={(e)=>handleConnect(e)}> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0",
                  }}
                  name="user_id"
                  label="User Id"
                  placeholder="Enter User Id"
                  value={valuesSelect?._id}
                  disabled
                />
                <TextField
                  sx={{
                    width: "100%",
                    margin: "10px 0",
                  }}
                  name="user_name"
                  label="User Name"
                  placeholder="Enter user name"
                  value={manualConnectionData?.user_name}
                  onChange={(e) =>
                    setManualConnectionData({
                      ...manualConnectionData,
                      user_name: e.target.value,
                    })
                  }
                />
              </Box>
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="email"
                label="Email"
                placeholder="Enter email"
                value={manualConnectionData?.email}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    email: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="company"
                label="Company Name"
                placeholder="Enter Company Name"
                value={manualConnectionData?.company}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    company: e.target.value,
                  })
                }
              />

              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="project_id"
                label="Project Id"
                placeholder="Enter Project Id"
                value={manualConnectionData?.project_id}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    project_id: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="business_id"
                label="Business Id"
                placeholder="Enter Business Id"
                value={manualConnectionData?.business_id}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    business_id: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="display_name"
                label="Display Name"
                placeholder="Enter Display Name"
                value={manualConnectionData?.display_name}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    // display_name: e.target.value, 
                  })
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  margin: "10px 0",
                }}
                name="contact"
                label="WABA Number"
                placeholder="WABA Number"
                value={manualConnectionData?.contact}
                onChange={(e) =>
                  setManualConnectionData({
                    ...manualConnectionData,
                    contact: e.target.value,
                  })
                }
              />

              <Box mt={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => handleConnect()}
                >
                  Submit
                </Button>
              </Box>
              {/* </form> */}
            </Box>
          </Box>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
}

const filterOptions: Record<string, string[]> = {
  name: [
    "contains",
    "equals",
    "starts with",
    "ends with",
    "is empty",
    "is not empty",
    "is any of",
  ],
  endDate: [
    "is",
    "is not",
    "is after",
    "is on or after",
    "is before",
    "is on or before",
    "is empty",
    "is not empty",
    "is between",
    "is not between",
  ],
  whatsappBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
  smsBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
  emailBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
};
